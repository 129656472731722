const urljoin = require("url-join");
const bigDecimal = require("js-big-decimal");
const { orderBy } = require("lodash-es");

const BASE_URL_API = process.env.VUE_APP_BASE_URL_API;

const BASE_URL_API_WS = process.env.VUE_APP_BASE_URL_API_WS;

export const CUS_URL = process.env.VUE_APP_CUS_URL;
export const LANDING_URL = process.env.VUE_APP_LANDING_URL;

export const WEBSITE_NAME = process.env.VUE_APP_WEBSITE_NAME;
export const WEBSITE_TITLE = process.env.VUE_APP_WEBSITE_TITLE;
export const TITLE_T_AND_C = process.env.VUE_APP_TITLE_T_AND_C;
export const WEBSITE = `${WEBSITE_NAME} ${WEBSITE_TITLE}`;

export const BANK_URL = `${BASE_URL_API}/images/bank/`;
export const IMAGE_URL = `${BASE_URL_API}/images/`;

export const API_URL = `${BASE_URL_API}/api/`;
export const API_URL_WS = `${BASE_URL_API_WS}/subscriptions`;
export const API_URL_GQL = `${BASE_URL_API}/api-backend`;

export const IS_SELLER = process.env.VUE_APP_SELLER == "1";

export const HIDE_FOOTER = process.env.VUE_APP_HIDE_FOOTER == "1";

export const imageUrl = process.env.VUE_APP_IMAGE_URL;
export const imageUrlApi = url => url && urljoin(imageUrl, url || "");
export const banks = [
  { label: "ธนาคารกสิกรไทย - Kasikornbank", value: "kbank" },
  { label: "ธนาคารกรุงเทพ - Bangkok Bank", value: "bbl" },
  { label: "ธนาคารไทยพาณิชย์ - Siam Commercial Bank", value: "scb" },
  { label: "ธนาคารกรุงไทย - Krungthai Bank", value: "ktb" },
  { label: "ธนาคารกรุงศรีอยุธยา - Bank of Ayudhya (Krungsri)", value: "bay" },
  { label: "ธนาคารซิตี้แบงค์ - Citibank", value: "citi" },
  { label: "ธนาคารออมสิน - Government Savings Bank", value: "gsb" },
  { label: "ธนาคารทหารไทย - TMB Bank", value: "tmb" },
  { label: "ธนาคารซีไอเอ็มบีไทย - CIMB Thai Bank", value: "cimb" },
  { label: "ธนาคารอาคารสงเคราะห์ - Government Housing Bank", value: "ghb" }
];
export const getBankName = name => {
  const found = banks.find(x => x.value === name);
  return found ? found.label : name;
};

export const encodeQueryData = data => {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  return ret.join("&");
};

export const getPriceLogistics = (logistics, { kg = 0 } = {}) => {
  if (!logistics) {
    return 0;
  }
  let num = kg;
  const newLogistics = JSON.parse(JSON.stringify(logistics));
  newLogistics.logisticsPriceList = orderBy(
    logistics.logisticsPriceList,
    ["min"],
    ["desc"]
  );
  for (let i = 0; i < (newLogistics.logisticsPriceList || []).length; i += 1) {
    const p = newLogistics.logisticsPriceList[i];
    if (num >= p.min) {
      return p.price;
    }
  }
  return 0;
};

export const calculateCube = (data, amount = 1) => {
  if (!data) {
    return 0;
  }
  let val =
    ((data.width || 0) * (data.height || 0) * (data.long || 0)) / 1000000;
  val *= amount;
  return bigDecimal.round(Math.floor(val * 10000) / 10000, 4);
};

export const calculateWeight = (weight, amount = 1) => {
  if (!weight) {
    return 0;
  }
  const val = weight * amount;
  return bigDecimal.round(Math.floor(val * 100) / 100, 2);
};

export const multipy = (x, y) => {
  return Number(
    bigDecimal.round(
      bigDecimal.multiply(x, y),
      2,
      bigDecimal.RoundingModes.DOWN
    )
  );
};

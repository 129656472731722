export default [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        name: "profile",
        path: "/profile",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/profile/Profile.vue")
      },
      {
        name: "product_seller_list",
        path: "/product/all",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/product/ProductSellerList.vue")
      },
      {
        name: "product_seller",
        path: "/product/:id",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/product/ProductSeller.vue")
      },
      {
        name: "chat_seller",
        path: "/chat/seller",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/chat/SellerChat.vue")
      },
      {
        name: "seller",
        path: "/seller",
        component: () => import("@/view/layout/LayoutSeller.vue"),
        children: [
          {
            name: "seller_order_list",
            path: "/seller/sale/order",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () =>
              import("@/view/pages/seller/order/SellerOrderList.vue")
          },
          {
            name: "seller_order_detail",
            path: "/seller/sale/order/:id",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () =>
              import("@/view/pages/seller/order/SellerOrderDetail.vue")
          },
          {
            name: "product_list",
            path: "/seller",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () =>
              import("@/view/pages/seller/product/ProductList.vue")
          },
          {
            name: "product_list",
            path: "/seller/info",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () => import("@/view/pages/seller/info/SellerInfo.vue")
          },
          {
            name: "product_list",
            path: "/seller/product",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () =>
              import("@/view/pages/seller/product/ProductList.vue")
          },
          {
            name: "product_new",
            path: "/seller/product/new",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () =>
              import("@/view/pages/seller/product/ProductCreate.vue")
          },
          {
            name: "product_detail",
            path: "/seller/product/:id",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () =>
              import("@/view/pages/seller/product/ProductDetail.vue")
          },
          {
            name: "inventory_list",
            path: "/seller/inventory",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () =>
              import("@/view/pages/seller/inventory/InventoryList.vue")
          }
        ]
      },
      {
        name: "messages",
        path: "/messages",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/chat/PrivateChat.vue")
      },
      {
        name: "ChangePassword",
        path: "/password-management",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/profile/ChangePassword.vue")
      },
      {
        name: "PayShippingCreate",
        path: "/forwarder/payshipping/create",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () =>
          import("@/view/pages/payshipping/PayShippingCreate.vue")
      },
      {
        name: "PayShippingCheckout",
        path: "/forwarder/payshipping/checkout",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () =>
          import("@/view/pages/payshipping/PayShippingCheckout.vue")
      },
      {
        path: "/shop",
        name: "shop",
        component: () => import("@/view/pages/shop/Shop.vue"),
        children: [
          {
            name: "sale_order_list",
            path: "/shop/sale",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () => import("@/view/pages/shop/SellerOrderList.vue")
          },
          {
            name: "sale_order_detail",
            path: "/shop/sale/:id",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () => import("@/view/pages/shop/SellerOrderDetail.vue")
          },
          {
            name: "order_list",
            path: "/shop/order",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () => import("@/view/pages/shop/OrderList.vue")
          },
          {
            name: "order_detail",
            path: "/shop/order/:id",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () => import("@/view/pages/shop/OrderDetail.vue")
          },
          {
            name: "product",
            path: "/shop/search/:url",
            meta: {
              authRequired: true,
              permission: "user"
            },
            component: () => import("@/view/pages/product/Product.vue")
          }
        ]
      },
      {
        name: "forwarder",
        path: "/forwarder",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/transport/TransportList.vue")
      },
      {
        name: "forwarder_create",
        path: "/forwarder/create",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/transport/TransportCreate.vue")
      },
      {
        name: "forwarder_detail",
        path: "/forwarder/:id",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/transport/TransportDetail.vue")
      },
      {
        name: "shippay_bill",
        path: "/shippay/bill",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/paybill/PayBillList.vue")
      },
      {
        name: "shippay_bill_detail",
        path: "/shippay/bill/:id",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/paybill/PayBillDetail.vue")
      },
      {
        name: "payment_list",
        path: "/payments",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/payments/PaymentList.vue")
      },
      {
        name: "payment_create",
        path: "/payments/create",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/payments/PaymentCreate.vue")
      },
      {
        name: "payment",
        path: "/payments/:id",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/payments/Payment.vue")
      },
      {
        name: "seller_cart",
        path: "/cart/seller",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/cart/SellerCart.vue")
      },
      {
        name: "seller_checkout",
        path: "/checkout/seller",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/cart/SellerCheckout.vue")
      },
      {
        name: "cart",
        path: "/cart",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/cart/Cart.vue")
      },
      {
        name: "checkout",
        path: "/checkout",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/cart/Checkout.vue")
      },
      {
        name: "wallet",
        path: "/wallet",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/wallet/Wallet.vue")
      },
      {
        name: "topup_create",
        path: "/wallet/topup/create",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/wallet/TopupCreate.vue")
      },
      {
        name: "topup",
        path: "/wallet/topup/:id",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/wallet/Topup.vue")
      },
      {
        name: "withdraw_create",
        path: "/wallet/withdraw/create",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/wallet/WithdrawCreate.vue")
      },
      {
        name: "withdraw",
        path: "/wallet/withdraw/:id",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/wallet/Withdraw.vue")
      },
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          authRequired: true,
          permission: "user"
        },
        component: () => import("@/view/pages/dashboard/Dashboard.vue")
      },
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "page-not-authorized",
        component: () => import("@/view/pages/error/Error-7.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/view/pages/auth/Auth"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/view/pages/auth/Login.vue")
      },
      {
        name: "signup",
        path: "/signup",
        component: () => import("@/view/pages/auth/Register.vue")
      },
      {
        name: "resetPassword",
        path: "/reset-password",
        component: () => import("@/view/pages/auth/ResetPassword.vue")
      }
    ]
  },
  {
    name: "waitVerify",
    path: "/wait",
    component: () => import("@/view/pages/auth/WaitVerify.vue")
  },
  {
    name: "verifyUser",
    path: "/verify",
    component: () => import("@/view/pages/auth/VerifyUser.vue")
  },
  {
    path: "*",
    redirect: "/404"
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/view/pages/error/Error-7.vue")
  }
];
